<template>

  <!--<div class="modal fade normal_modal form_modal modal-create-brand" id="modal-add-feed" tabindex="-1" role="dialog">-->

  <!--<div class="modal-dialog" style="    max-width: 550px;">-->
  <!--<div class="modal-content">-->
  <!--<div class="modal-header">-->
  <!--<button type="button " class="close" data-dismiss="modal" aria-hidden="true">&times;-->
  <!--</button>-->
  <!--<h4 class="modal-title">-->
  <!--<template v-if="getRSSAdd._id">-->
  <!--Update RSS Feed-->
  <!--</template>-->
  <!--<template v-else>-->
  <!--Add RSS Feed-->
  <!--</template>-->
  <!--</h4>-->
  <!--</div>-->

  <!--<div class="modal-body">-->

  <!--&lt;!&ndash; Create Client Form &ndash;&gt;-->

  <!--<div  class="basic_form clear">-->

  <!--<div class="input_field col-md-12">-->
  <!--<label>Select a Campaign</label>-->
  <!--<select name="" id="" v-model="getRSSAdd.cta_id"-->
  <!--:class="{'input-field-error': validations.campaign}">-->
  <!--<option selected="true" value="">Select a Campaign</option>-->
  <!--<option v-for="cta in getCampaignsAll" :value="cta._id">{{cta.name}}</option>-->
  <!--</select>-->
  <!--<span class="input-error" v-if="validations.campaign">-->
  <!--{{messages.campaign}}-->
  <!--</span>-->
  <!--</div>-->

  <!--<div class="input_field col-md-12">-->
  <!--<label>The RSS Feed URL</label>-->
  <!--<input placeholder="Enter RSS Feed URL e.g https://techcrunch.com/feed"-->
  <!--v-model="getRSSAdd.url" type="text"-->
  <!--@keyup.enter="validateAndStoreRSS()"-->
  <!--:class="{'input-field-error': validations.url || validations.url_valid}"-->
  <!--&gt;-->
  <!--<span class="input-error" v-if="validations.url">-->
  <!--{{messages.url}}-->
  <!--</span>-->
  <!--<span class="input-error" v-if="validations.url_valid">-->
  <!--{{messages.url_valid}}-->
  <!--</span>-->
  <!--</div>-->
  <!--<div class="col-md-12">-->

  <!--&lt;!&ndash;<input type="checkbox" id="rssFeedOption">&ndash;&gt;-->
  <!--&lt;!&ndash;v-model="getSaveRss.autoFetch">&ndash;&gt;-->

  <!--<p style="margin-bottom: 0;" class="text-left alert alert-warning info_msg"><i-->
  <!--aria-hidden="true" class="fa fa-info-circle"></i>-->
  <!--Your RSS feed content will be automatically pulled after every 30 minutes.</p>-->

  <!--</div>-->

  <!--</div>-->

  <!--</div>-->

  <!--&lt;!&ndash; Modal Actions &ndash;&gt;-->
  <!--<div class="modal-footer">-->
  <!--<a href="javascript:;" @click.prevent="validateAndStoreRSS()" :disabled="getRSSLoaders.store"-->
  <!--class="btn&#45;&#45;cta btn&#45;&#45;small btn&#45;&#45;loader white&#45;&#45;loader loader&#45;&#45;left">-->
  <!--<clip-loader v-if="getRSSLoaders.store" :size="size" :color="color"></clip-loader>-->

  <!--<span>-->
  <!--<template v-if="getRSSAdd._id">-->
  <!--Update-->
  <!--</template>-->
  <!--<template v-else>-->
  <!--Add-->
  <!--</template>-->
  <!--</span>-->

  <!--</a>-->
  <!--<button type="button" class="btn&#45;&#45;cta btn&#45;&#45;small btn-&#45;&#45;transparent " data-dismiss="modal">Close-->
  <!--</button>-->

  <!--</div>-->

  <!--</div>-->
  <!--</div>-->
  <!--</div>-->

  <b-modal modal-class="modal_basic modal-add-feed" id="modal-add-feed" hide-footer hide-header>
    <img @click="$bvModal.hide('modal-add-feed')" class="close_modal" src="/assets/img/cross.svg" alt="">

    <div class="basic_form modal_content">

      <div class="heading">
        <h3>
          <template v-if="getRSSAdd._id">
            Update RSS Feed
          </template>
          <template v-else>
            Add RSS Feed
          </template>
        </h3>
      </div>


      <b-dropdown ref="campaigns_dropdown_quick_link" right
                  class="dropdown-menu-right default_style_dropdown" :no-caret="true">
        <!--d-flex align-items-center-->
        <div @click="fetchCampaignOnDropdown" class="dropdown_header br-10 rounded_shape d-flex align-items-center"
             slot="button-content"
             data-cy="select-camp">
          <span class="text">
            <template>{{ getSelectedCampaign() }}</template>
          </span>
          <span class="arrow_icon ml-auto"><i class=" fal fa-angle-down ml-3"></i></span>
        </div>
        <ul id="campaign-dropdown-scroll" @scroll="lazyScroll('campaign-dropdown-scroll','fetchCampaignsList',1)"
            class="clear inner">
          <template v-if="getCampaignsList && getCampaignsList.length && getCampaignsList.length > 7 || getCampaigns.search">
            <div class="mr-2 mb-2 mt-2 search_input_block search_input_block_for_dropdown no_animation d-flex">
              <input placeholder="Search for campaign"
                     type="text"
                     class="search_input_block_for_dropdown" :value="getCampaigns.search"
                     @input="debounceCampaignDropdownSearch" data-cy="search-camp"> <i
              class="fal fa-search"></i>
            </div>
            <hr>
          </template>
          <template v-if="getCampaignsList && getCampaignsList.length">
            <li class="list_item_li" @click="$refs.campaigns_dropdown_quick_link.visible = false"
                v-for="(cta, index) in getComponentCampaignList"
                @click.prevent="selectCampaignForRss(cta),$refs.campaigns_dropdown_quick_link.hide(true)"
                v-if="cta.name" :key="index" data-cy="camps">

              <div class="d-flex align-items-center profile__box">
                <div class="avatar">
                  <div class="brand_img"
                       :style="brandImageLink(cta.brand.avatar)"></div>
                </div>
                <div class="content">
                  <div class="name">
                    {{ limitTextLength(cta.name, 21) }}
                  </div>
                </div>
              </div>
            </li>
            <li v-if="getCampaignLoaders.retrieve" class="list_item_li">
              <clip-loader :color="'#168eea'" :size="'16px'"></clip-loader>
            </li>
          </template>
          <li v-else-if="getCampaignLoaders.retrieve" class="list_item_li">
            <clip-loader :color="'#168eea'" :size="'16px'"></clip-loader>
          </li>
          <li v-if="!getCampaignLoaders.retrieve && !getComponentCampaignList.length && getCampaigns.search.length" class=" no_text_li">{{ searchFiltersMessages('').search_field_dropdown }}</li>
          <li v-else-if="!getCampaignLoaders.retrieve && !getCampaignsList.length" class=" no_text_li">{{ searchFiltersMessages('campaigns').empty_list_dropdown }}</li>
        </ul>
      </b-dropdown>
      <span class="input-error" v-if="validations.campaign">{{ messages.campaign }}</span>

      <div class=" mt-3 input_icon_text d-flex align-items-center profile__box"
           :class="{'input-field-error': validations.url || validations.urlValid || validations.urlLength}">
        <div class="content">
          <div class="url">The RSS Feed URL</div>
          <div class="name">
            <div class="validation_input">
              <url
                v-model="getRSSAdd.url"
                @onEnter="validateAndStoreRSS($event)"
                :validations="validations"
                v-bind:blur="() => getRSSAdd.url = focusoutConcatenation(getRSSAdd.url)"
                ref="urlRef"
                data-cy="rss-url"
                placeholder="Enter RSS Feed URL e.g https://techcrunch.com/feed"></url>
            </div>
          </div>
        </div>
      </div>
      <span class="input-error" v-if="validations.url">{{ messages.url }}</span>
      <span class="input-error" v-else-if="validations.urlLength">{{ messages.urlLength }}</span>
      <span class="input-error" v-else-if="validations.urlValid">{{ messages.urlValid }}</span>
      <div class="alert-box  warning">
        <div class="inner_box">
          <p class="mt-0">Your RSS feed content will be automatically pulled after every 30 minutes.</p>
        </div>
      </div>

      <div class=" btn_block text-left">

        <button @click.prevent="validateAndStoreRSS()" :disabled="getRSSLoaders.store"
                class="btn---cta btn-blue with-shadow btn-round  btn--loader white--loader loader--left btn-bold">
          <clip-loader v-if="getRSSLoaders.store" :size="size" :color="color"></clip-loader>
          <span>
                            <template v-if="getRSSAdd._id">
                                Update
                            </template>
                            <template v-else>
                                Add
                            </template>
                        </span>

        </button>
        <button type="button" class="ml-2 btn---cta light-blue btn-round  btn-bold"
                @click="$bvModal.hide('modal-add-feed')"><span>Close</span>
        </button>

      </div>

    </div>
  </b-modal>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import GuidedTour from '@/views/guided-tour/GuidedTour.vue'
import { DARK_PURPLE_LOADER_COLOR } from '@/common/constants'
import URL from '@/ui/URL'
import { rssFeedMessages, rssFeedValidations, searchFiltersMessages } from '../../../../common/attributes'

export default ({

  components: {
    GuidedTour,
    'url': URL
  },
  data: function () {
    return {
      size: '14px',
      color: DARK_PURPLE_LOADER_COLOR,
      modalStatus: {
        keyboard: false,
        value: 'static'
      },
      messages: rssFeedMessages,
      validations: rssFeedValidations,
      search_query: '',
      searchFiltersMessages: searchFiltersMessages
    }
  },

  created () {
  },
  computed: {
    ...mapGetters(['getRSSAdd', 'getRSS', 'getRSSLoaders', 'getCampaigns', 'getComponentCampaignList', 'getCampaignLoaders', 'getCampaignsList'])
  },
  watch: {
    'getRSSAdd.cta_id' (value) {
      if (value && value.length > 1) this.validations.campaign = false
    }
  },
  methods: {
    ...mapActions([]),
    async validateAndStoreRSS () {
      const payload = this.getRSSAdd
      this.validations.campaign = this.requiredCheck(payload.cta_id)
      this.validations.url = this.requiredCheck(this.getRSSAdd.url)
      this.getRSSAdd.url = this.urlConcatenation(this.getRSSAdd.url)
      this.validations.urlValid = !this.isValidURL(this.getRSSAdd.url)
      let result = Object.keys(this.validations).every(k => this.validations[k] === false)
      if (result) {
        let res = await this.$store.dispatch('storeRSS')
        if (res.data.status) {
          this.$bvModal.hide('modal-add-feed')
        }
      }
    },
    selectCampaignForRss (cta) {
      this.getRSSAdd.cta_id = cta._id
      this.getSelectedCampaign()
    },

    /**
     *  getting the selected campaign name
     * @returns {string|*|string}
     */
    getSelectedCampaign () {

      // if creating new rss so cta_id is null
      if (!this.getRSSAdd.cta_id) {
        return 'Select your Campaign'
      }

      // while editing rss so ge campaign name from list if not then get from rss feed list
      const campaignName = this.getCampaignName(this.getRSSAdd.cta_id)
      if (!campaignName) {
        const campaign = this.getRSS.items.find(item => this.getRSSAdd.cta_id === item.cta_id)
        return campaign && campaign.cta && campaign.cta.name ? campaign.cta.name : 'Select your Campaign'
      }

      // is selected campaign exists then simple returs its name
      return campaignName && campaignName.name ? campaignName.name : 'Select your Campaign'

    }
  }

})
</script>
