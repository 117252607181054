<template>

  <div class="rss_component">
    <transition name="slide">

      <div class="container">

        <add-rss-feed-modal></add-rss-feed-modal>
        <rss-feed-preview-modal></rss-feed-preview-modal>

        <div class="rss_component_inner">

          <div class="component_head d-flex align-items-center">
            <div class="head_left">
              <!-- <button
                v-if="getProfile.policy.can_add_rss"
                @click.prevent="showAddFeedModal()"
                class="btn---cta btn-blue with-shadow btn-round   btn-bold"
              >
                <span>Add RSS Feed</span>
                <span class="icon_right">
                  <i class="circle_icon d-flex align-items-center justify-content-center  fal fa-plus  "></i>
                </span>
              </button> -->
              <ui-button
                :type="'add'"
                :color="'#ffffff'"
                :bgColor="'#3988fe'"
                :text="'Add RSS Feed'"
                :icon="'fal fa-plus'"
                :iconPosition="'right'"
                :iconBackground="'rgba(31, 33, 110, 0.35)'"
                :iconBorderRadius="'50%'"
                v-if="getProfile.policy.can_add_rss"
                v-on:click="showAddFeedModal()"
              />
            </div>
            <!--<div class="head_right ml-auto d-flex align-items-center justify-content-end ">-->

            <!--</div>-->

          </div>

          <div class="white_box ">
            <div class="box_inner">
              <div class="box_head d-flex align-items-center">
                <div class="left">
                  <h2>RSS Feeds</h2>
                  <p class=" ">
                    You can add RSS feeds to your campaigns and escalate your brand marketing. Your
                    RSS
                    feed content will be automatically pulled after every 30 minutes which you can
                    share
                    among your audience via your shortened {{ getSiteDetails.agency_name }} link.
                  </p>
                </div>
                <div class="right more_width d-flex align-items-center justify-content-end ">

                  <div
                    class="  search_input_block no_animation  "
                    :class="{active_input : getRSS.search}"
                  >
                    <input
                      v-model="getRSS.search"
                      placeholder="Search RSS Feed"
                      @keyup.enter="fetchRSSList(1)"
                      required
                      type="text"
                    >
                    <i
                      class="fal fa-search"
                      @click.prevent="fetchRSSList(1)"
                    ></i>
                  </div>

                </div>

              </div>
              <div class="box_content">

                <div class="table_container">
                  <table class="table list-content">
                    <thead class="list-content-head filter_head">
                    <tr>
                      <th
                        class=" text-left"
                        style="width: 260px;"
                      >Feed
                      </th>
                      <th class=" text-center">Status</th>
                      <th class=" text-center">URL</th>
                      <th class=" text-center">Campaign</th>
                      <th class=" text-center">Created</th>
                      <th class=" text-center">Last Crawled</th>
                      <th class=" text-center">Total Links Shortened</th>
                      <th style="text-align: right;">Actions</th>
                    </tr>
                    </thead>
                    <tbody class="list-content-body">

                    <!--............. for loader ..............-->

                    <tr v-if="getRSSLoaders.show">
                      <td colspan="7">
                        <clip-loader
                          :size="size"
                          :color="color"
                        ></clip-loader>
                      </td>
                    </tr>

                    <tr
                      v-else-if="getRSS.items.length > 0"
                      v-for="(rss,index) in getRSS.items"
                    >

                      <td style="text-align: left;">
                        https://rss.replug.io/rss/{{ rss.file_id }}
                      </td>
                      <td style="text-align: center;">

                        <div v-tooltip="rssFeedStatusMessages[rss.last_crawled.type]" style="height: 1.7rem"
                             v-if="rss.last_crawled.type && rss.last_crawled.type === 'processing'" class="progress">
                          <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                               aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: 100%">
                            Processing...
                          </div>
                        </div>

                        <div v-else-if="rss.last_crawled && rss.last_crawled.type"
                             v-tooltip="rssFeedStatusMessages[rss.last_crawled.type]"
                             :class="rssFeedStatusColors[rss.last_crawled.type]" class="alert mb-0 p-1 text-capitalize"
                             role="alert">
                          {{ rss.last_crawled.type.replace(/_/g, " ") }}
                        </div>
                      </td>
                      <td style="text-align: center;">
                        <p v-tooltip="limitTextLength(rss.url,40)" class="rss_url">
                          {{ limitTextLength(rss.url, 40) }}</p>
                      </td>
                      <td
                        style="text-align: center;"
                        v-if="rss.cta"
                      >
                        <p v-tooltip="limitTextLength(rss.cta.name,40)" class="rss_url">
                          {{ limitTextLength(rss.cta.name, 40) }}</p>
                      </td>
                      <td
                        style="text-align: center;"
                        v-else
                      >
                        -
                      </td>

                      <td style="text-align: center;">
                        {{ rss.created_at | relativeCaptilize }}
                      </td>

                      <td
                        style="text-align: center;"
                        class="created_at"
                        v-if="rss.last_crawled && rss.last_crawled.time"
                      >
                        {{ rss.last_crawled.time | relativeCaptilize }}
                      </td>
                      <td
                        style="text-align: center;"
                        class="created_at"
                        v-else
                      >
                        -
                      </td>
                      <td style="text-align: center;">
                        {{ rss.last_crawled.records }}
                      </td>
                      <td class="text-right">
                        <template v-if="!rss.fetch_status">
                          <div class="d-flex align-items-center justify-content-end">
                            <span class="actions text-center mr-1">
                              <i
                                v-tooltip="'Remove'"
                                @click.prevent="deleteRSS({feedId: rss._id, index: index})"
                                class="fas fa-trash text-danger border-danger"
                                aria-hidden="true"
                              ></i>
                            </span>
                            <clip-loader
                              style="text-align: right"
                              :size="size"
                              :color="color"
                            ></clip-loader>
                          </div>
                        </template>
                        <template v-else>
                          <b-dropdown
                            ref="dropdownActionMenu"
                            right
                            class="d-inline-block table_action_dropdown  dropdown-menu-right default_style_dropdown"
                            :no-caret="true"
                          >
                            <!--d-flex align-items-center-->
                            <div
                              class=" dropdown_header no_arrow text-right"
                              slot="button-content"
                            >
                                <span class="text">
                                  <i class="fal fa-ellipsis-h fw-500"></i>
                                </span>
                              <!--<span class="arrow_icon ml-auto">-->
                              <!--<i class=" fal fa-angle-down ml-3"></i>-->
                              <!--</span>-->
                            </div>
                            <ul class="clear inner ">

                              <li class="list_item">
                                <a
                                  @click.prevent="showEditFeedModal(rss)"
                                  target="_blank"
                                  href=""
                                >
                                  <i
                                    class="far  fa-pencil"
                                    aria-hidden="true"
                                  ></i>
                                  Edit
                                </a>
                              </li>
                              <li class="list_item">
                                <a
                                  @click.prevent="recrawlRSS({feedId: rss._id,index: index})"
                                  target="_blank"
                                  href=""
                                >
                                  <i
                                    class="far  fa-refresh"
                                    aria-hidden="true"
                                  ></i>
                                  Recrawl
                                  <clip-loader
                                    style="display: inline-block; margin-left: 10px;"
                                    :size="'12px'"
                                    :color="color"
                                    v-if="getRSSLoaders.recrawl"
                                  ></clip-loader>

                                </a>
                              </li>
                              <li class="list_item">
                                <a
                                  @click.prevent="previewRSS('https://rss.replug.io/rss/' + rss.file_id)"
                                  target="_blank"
                                  href=""
                                >
                                  <i
                                    class="far  fa-eye"
                                    aria-hidden="true"
                                  ></i>
                                  Preview
                                </a>
                              </li>
                              <li class="list_item">
                                <a
                                  @click.prevent="deleteRSS({feedId: rss._id, index: index})"
                                  target="_blank"
                                  href="javascript:;"
                                >
                                  <i
                                    class="far  fa-trash"
                                    aria-hidden="true"
                                  ></i>
                                  Remove
                                </a>
                              </li>
                            </ul>

                          </b-dropdown>

                          <span
                            class="actions text-center"
                            style="margin-left: 5px;"
                          >
                              <i
                                v-tooltip="'Pause'"
                                recrawlRSS
                                @click.prevent="changeRSSStatus({feedId: rss._id, status: true,index: index})"
                                v-if="!rss.pause"
                                class="far fa-pause active"
                                aria-hidden="true"
                              ></i>
                              <i
                                v-tooltip="'Active'"
                                style="padding-left: 4px;"
                                @click.prevent="changeRSSStatus({feedId: rss._id, status: false,index: index})"
                                v-else
                                class="far fa-play "
                                aria-hidden="true"
                              ></i>
                            </span>

                        </template>
                      </td>
                    </tr>
                    <tr v-else>
                      <td colspan="9" class="">
                        <p class="no-rows"><i class="fab fa-bandcamp"
                                              aria-hidden="true"></i>{{ getFilteredMessage('RSS Feed', getRSS.search) }}
                        </p>
                      </td>
                    </tr>
                    <div class="clearfix"></div>

                    </tbody>
                  </table>
                </div>
              </div>

            </div>
            <div class="col-md-12">
              <div class="display-center txt-center" v-if="getRSS.total_items > getRSS.limit">
                <pagination
                  :clickedMethod="paginateRSS"
                  :resultCount="getRSS.total_items"
                  :itemsPerPage="getRSS.limit"
                  :currentPage="getRSS.page"
                  :totalPagesNumber="getRSS.total_pages"
                ></pagination>
              </div>
            </div>
          </div>
        </div>

      </div>
    </transition>
  </div>

</template>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}
</style>
<script>

import {mapActions, mapGetters} from 'vuex'
import Dropdown from '@/ui/Dropdown.vue'

import Pagination from '@/misc/Pagination.vue'
import AddRSSFeedModal from './dialogs/AddRSSFeedModal.vue'
import RSSFeedPreviewModal from './dialogs/RSSFeedPreviewModal.vue'
import {rssTypes} from '@/state/modules/mutation-types'
import {DARK_PURPLE_LOADER_COLOR} from '@/common/constants'
import {rssFeedStatusMessages, rssFeedStatusColors} from '@/common/attributes'

export default ({
  components: {
    'add-rss-feed-modal': AddRSSFeedModal,
    'rss-feed-preview-modal': RSSFeedPreviewModal,
    Pagination,
    Dropdown

  },
  data: function () {
    return {
      size: '20px',
      color: DARK_PURPLE_LOADER_COLOR,
      rssFeedStatusMessages: rssFeedStatusMessages,
      rssFeedStatusColors: rssFeedStatusColors
    }
  },

  async created () {
    this.getRSS.search = ''
    await this.fetchRSSList()
  },
  mounted () {
    if (window.socket) {
      socket.on('rss_complete:' + this.getProfile.username, (data) => {
        this.$store.dispatch('fetchRSSList')
      })
    }
  },
  beforeDestroy () {
  },

  computed: {
    ...mapGetters(['getRSS', 'getRSSLoaders'])
  },

  methods: {
    ...mapActions([
      'fetchRSSList',
      'changeRSSStatus',
      'recrawlRSS',
      'previewRSS',
      'deleteRSS',
      'fetchCampaignsList'
    ]),
    showAddFeedModal () {
      this.$store.commit(rssTypes.SET_RSS_ADD_DEFAULT)
      // $('#modal-add-feed').modal('show')
      this.$bvModal.show('modal-add-feed')
    },
    showEditFeedModal (rss) {
      this.$store.commit(rssTypes.SET_RSS_ADD_VALUE, rss)
      // $('#modal-add-feed').modal('show')
      this.$bvModal.show('modal-add-feed')
    },
    paginateRSS (page) {
      this.fetchRSSList(page)
    }
  },
  watch: {
    async 'getRSS.search' (value) {
      if (value.length === 0) {
        await this.fetchRSSList(1)
      } else if (value && value.length >= 3) {
        await this.fetchRSSList(1)
      }
    }
  }

})
</script>
