<template>

    <div id="rssFeedModal" class="modal normal_modal slide_modal fade rssFeedModal" role="dialog">
        <div class="modal-dialog">
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">RSS Feed Preview</h4>
                    <button type="button" class="close" data-dismiss="modal">
                        <img src="/img/cancel.png" alt="">
                    </button>
                </div>
                <div class="modal-body">

                    <template v-if="getRSSLoaders.preview">
                        <div style="padding: 15px;">
                            <clip-loader :size="size" :color="color"></clip-loader>
                        </div>

                    </template>
                    <template v-else>
                        <div class="top_filter">
                            <div class="filter_left">
                                <div class="search_input_no_animate">
                                    <input placeholder="Search by keyword..." v-model="getRSSPreview.search" type="text"
                                           class="form-control">
                                    <i class="fal fa-search"></i>
                                </div>
                            </div>

                            <div class="filter_right " v-if="false">

                                <div class="toggle_filters">
                                    <span class="text">All</span>
                                    <span class="text active">Shared Links<span class="count">( 23 )</span> </span>
                                    <span class="text">Non-Shared Links<span class="count">( 4 )</span> </span>
                                </div>

                            </div>
                        </div>
                        <div class="feed_preview_list" v-if="getRSSPreview.data && getRSSPreview.data.length > 0">

                            <!--shared-->
                            <div v-for="data in getRSSPreview.data" class="feed_item">
                                <div class="feed_image">
                                    <div class="main_img"
                                         v-if="data['media_content'] && data['media_content']['0'] && data['media_content']['0']['url']"
                                         :style="getFeedImage(data['media_content']['0']['url'])"></div>
                                    <div v-else class="main_img"
                                         style="background: url('/img/no_image_found.png');"></div>
                                </div>
                                <div class="feed_detail">
                                    <h2 v-if="data.title">{{data.title}}</h2>
                                    <div class="feed_source_detail">
                                        <p>
                                            <a v-if="data.link" :href="data.link" target="_blank">{{trimDomainURL(data.link)}}</a>
                                            <span v-if="data.published">{{data.published}}</span>
                                        </p>
                                    </div>
                                    <p class="desc" v-if="data.summary">
                                        {{limitTextWords(data.summary,14)}}
                                    </p>
                                    <div class="bottom_btn">
                                        <button class="btn blue" v-if="false">
                                            <i class="far fa-share"></i>
                                            <span>Shared Link</span>
                                        </button>

                                        <a class="btn blue" :href="data.orignal_link" target="_blank">
                                            <i class="far fa-star"></i>
                                            <span>Original Link</span>
                                        </a>

                                        <button class="btn blue"
                                                v-clipboard:copy="data.link"
                                                v-clipboard:success="onCopy"
                                                v-clipboard:error="onError">
                                            <i class="far fa-clone"></i>
                                            <span>Copy Link</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-center" style="padding: 15px;" v-else>No Result Found</div>
                    </template>

                </div>
            </div>

        </div>
    </div>

</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import { rssTypes } from '@/state/modules/mutation-types'
import {DARK_PURPLE_LOADER_COLOR} from "@/common/constants";

export default ({

  data: function () {
    return {

      size: '14px',
      color: DARK_PURPLE_LOADER_COLOR
    }
  },

  created: function () {
  },

  computed: {

    ...mapGetters([
      'getRSSPreview',
      'getRSSLoaders'
    ])

  },
  watch: {
    'getRSSPreview.search' (search) {
      if (search && (search.trim()).length > 0) {
        search = search.trim()
        if (search) {
          let feeds = this.getRSSPreview.original_data.filter(item => {
            return (
              (item.title && item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0) ||
                (item.link && item.link.toLowerCase().indexOf(search.toLowerCase()) >= 0) ||
                (item.summary && item.summary.toLowerCase().indexOf(search.toLowerCase()) >= 0))
          })
          this.$store.commit(rssTypes.SET_RSS_PREVIEW_DATA, feeds)
        } else {
          this.$store.commit(rssTypes.SET_RSS_PREVIEW_DATA, this.getRSSPreview.original_data)
        }
      } else {
        this.$store.commit(rssTypes.SET_RSS_PREVIEW_DATA, this.getRSSPreview.original_data)
      }
    }
  },
  methods: {

    ...mapActions([]),
    getFeedImage (url) {
      if (url && (url.trim()).length > 0) {
        return 'background: url(' + url + ');background-size: cover;background-position: center center'
      } else {
        return 'background: url(' + '/img/profile-default.png' + ') center center / cover;'
      }
    },
    trimDomainURL (url) {
      if (url != undefined) {
        url = url.replace('http://', '')
        url = url.replace('https://', '')
        url = url.replace('http://www.', '')
        url = url.replace('https://www.', '')
        url = url.replace('www.', '')
        return url.split('/')[0]
      } else {
        return ''
      }
    }
  }

})
</script>
